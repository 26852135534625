<template>
  <div class="send-tab">
    <!-- Amount -->
    <div>{{ $t("wallet.howMuchToSend") }}</div>
    <v-text-field type="number" :placeholder="$t('wallet.enterAmount')" />

    <!-- Address -->
    <div class="mt-8">{{ $t("wallet.whereToSend") }}</div>
    <v-text-field :placeholder="$t('wallet.pasteAddress')" />

    <!-- Submit -->
    <v-btn color="primary" block class="mt-10">{{ $t("common.send") }}</v-btn>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
